import { yupResolver } from '@hookform/resolvers/yup'
import { useConfirmPasswordResetMutation } from 'api/admins/confirmPasswordReset'
import getApiErrorHandler from 'api/errorHandler'
import { EApiErrorTypes } from 'api/types'
import Button from 'components/Button'
import Card from 'components/Card'
import Loading from 'components/Loading'
import Stack from 'components/Stack'
import {
  showErrorToast,
  showSuccessToast
} from 'components/ToastNotification/utils'
import PasswordInput from 'components/inputs/PasswordInput'
import AuthPageLayout from 'components/layout/AuthPageLayout'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom'
import theme from 'theme'
import routes from 'utils/routes'

import {
  StyledAuthPageCardContent,
  StyledAuthPageH1,
  StyledAuthPageHelpText
} from '../styled'

import schema from './schema'

interface IForgetPasswordFormValues {
  newPassword: string
}

const ResetPasswordPage = () => {
  const location = useLocation()
  const history = useHistory()
  const { token } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      token: searchParams.get('token')
    }
  }, [location.search])

  const { register, handleSubmit, formState, errors } =
    useForm<IForgetPasswordFormValues>({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: {
        newPassword: ''
      }
    })

  const { mutate: confirmPasswordRequest } = useConfirmPasswordResetMutation({
    onSuccess: () => {
      showSuccessToast('Success! You can sign in now.')
      history.replace(routes.signIn)
    },
    onError: getApiErrorHandler({
      errorMap: {
        [EApiErrorTypes.UserNotFound]:
          'Invalid password reset link. Try resetting your password again.'
      }
    })
  })

  if (!token) {
    showErrorToast('Invalid password reset link')

    return <Redirect exact to={routes.forgotPassword} />
  }

  const onSubmit = (values: IForgetPasswordFormValues) => {
    confirmPasswordRequest({ ...values, token: token ?? '' })
  }

  return (
    <AuthPageLayout>
      <Card>
        <StyledAuthPageCardContent>
          <form
            aria-label='reset-password-form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack orientation='vertical' space={4}>
              <StyledAuthPageH1>Set up your new password</StyledAuthPageH1>
              <PasswordInput
                ref={register}
                name='newPassword'
                label='New password'
                placeholder='Type in your new password'
                errorMessage={errors?.newPassword?.message}
              />
              <Button
                disabled={formState.isSubmitting}
                type='submit'
                variant='primary'
              >
                {formState.isSubmitting ? (
                  <Loading color={theme.colors.white} size='2rem' />
                ) : (
                  'Save new password'
                )}
              </Button>
            </Stack>
          </form>
        </StyledAuthPageCardContent>
      </Card>
      <StyledAuthPageHelpText>
        <Link to={routes.signIn}>Back to login</Link>
      </StyledAuthPageHelpText>
    </AuthPageLayout>
  )
}

export default ResetPasswordPage
