import styled from 'styled-components'
import theme from 'theme'

const HEADER_HEIGHT = '8rem'

const StyledAppPageLayout = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'sidebar content';
  grid-template-columns: 20.8rem auto;
  grid-template-rows: ${HEADER_HEIGHT} auto;
`

const StyledHeaderContainer = styled.div`
  grid-area: header;
`

const StyledSidebarContainer = styled.div`
  grid-area: sidebar;

  position: fixed;
  top: ${HEADER_HEIGHT};
  width: 20.8rem;
  max-height: calc(100vh - ${HEADER_HEIGHT});
  overflow-y: auto;

  background-color: ${theme.colors.grey[100]};
`

const StyledContentContainer = styled.div`
  grid-area: content;

  min-height: calc(100vh - ${HEADER_HEIGHT});
  background-color: ${theme.colors.grey[100]};
`

export {
  StyledAppPageLayout,
  StyledHeaderContainer,
  StyledSidebarContainer,
  StyledContentContainer
}
