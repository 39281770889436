import LogoIcon from 'components/icons/Logo'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import routes from 'utils/routes'

import { StyledHeaderContainer } from '../AppPageLayout/styled'

import {
  StyledAuthPageLayout,
  StyledContentContainer,
  StyledAuthPageHeader,
  StyledMainContent
} from './styled'

const AuthPageLayout: FC = ({ children }) => {
  return (
    <StyledAuthPageLayout>
      <StyledHeaderContainer>
        <StyledAuthPageHeader>
          <Link to={routes.signIn}>
            <LogoIcon />
          </Link>
        </StyledAuthPageHeader>
      </StyledHeaderContainer>
      <StyledContentContainer>
        <StyledMainContent>{children}</StyledMainContent>
      </StyledContentContainer>
    </StyledAuthPageLayout>
  )
}

export default AuthPageLayout
