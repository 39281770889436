import { useRequestPasswordResetMutation } from 'api/admins/requestPasswordReset'
import Card from 'components/Card'
import AuthPageLayout from 'components/layout/AuthPageLayout'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from 'utils/routes'

import { StyledAuthPageCardContent, StyledAuthPageHelpText } from '../styled'

import ForgotPasswordForm, {
  IForgotPasswordFormValues
} from './components/ForgotPasswordForm'
import ForgotPasswordSuccess from './components/ForgotPasswordSuccess'

const ForgotPasswordPage = () => {
  const [submittedEmail, setSubmittedEmail] = useState('')

  const { mutate: resetPassword } = useRequestPasswordResetMutation({
    onSuccess: (_, variables) => {
      setSubmittedEmail(variables.email)
    }
  })

  const onSubmit = ({ email }: IForgotPasswordFormValues) => {
    resetPassword({ email })
  }

  return (
    <AuthPageLayout>
      <Card>
        <StyledAuthPageCardContent>
          {submittedEmail ? (
            <ForgotPasswordSuccess submittedEmail={submittedEmail} />
          ) : (
            <ForgotPasswordForm onSubmit={onSubmit} />
          )}
        </StyledAuthPageCardContent>
      </Card>

      <StyledAuthPageHelpText>
        <Link to={routes.signIn}>Back to login</Link>
      </StyledAuthPageHelpText>
    </AuthPageLayout>
  )
}

export default ForgotPasswordPage
