import styled from 'styled-components'
import theme from 'theme'

import { StyledHeader } from '../Header/styled'

const HEADER_HEIGHT = '12rem'

const StyledAuthPageLayout = styled.div`
  height: 100vh;
  display: grid;
  grid-template-areas: 'header' 'content';
  grid-template-rows: ${HEADER_HEIGHT} auto;
  background-color: ${theme.colors.grey[100]};
`

const StyledAuthPageHeader = styled(StyledHeader)`
  background-color: transparent;
  box-shadow: none;
`

const StyledContentContainer = styled.div`
  grid-area: content;
`

const StyledMainContent = styled.main`
  height: 100%;
  display: grid;
  justify-items: center;
  align-content: start;
  gap: 4rem;
`

export {
  StyledAuthPageLayout,
  StyledContentContainer,
  StyledAuthPageHeader,
  StyledMainContent
}
