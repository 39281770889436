import { yupResolver } from '@hookform/resolvers/yup'
import getApiErrorHandler from 'api/errorHandler'
import Button from 'components/Button'
import Card from 'components/Card'
import Loading from 'components/Loading'
import Stack from 'components/Stack'
import { showSuccessToast } from 'components/ToastNotification/utils'
import PasswordInput from 'components/inputs/PasswordInput'
import TextInput from 'components/inputs/TextInput'
import AuthPageLayout from 'components/layout/AuthPageLayout'
import { useAuthContext } from 'context/AuthContext'
import RequestException from 'errors/RequestException'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import theme from 'theme'
import routes from 'utils/routes'

import {
  StyledAuthPageCardContent,
  StyledAuthPageH1,
  StyledAuthPageHelpText,
  StyledForgotPasswordLink
} from '../styled'

import schema from './schema'

interface ISignInFormValues {
  email: string
  password: string
}

const SignInPage: FC = () => {
  const location = useLocation()
  /**
   * If the admin user is redirected from Sign Up page he can have pre-filled email address
   */
  const email = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return searchParams.get('email')
  }, [location.search])

  const { handleSignIn } = useAuthContext()
  const { register, handleSubmit, formState, errors } =
    useForm<ISignInFormValues>({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: {
        email: email ?? '',
        password: ''
      }
    })

  const onSubmit = async (formData: ISignInFormValues) => {
    try {
      await handleSignIn(formData)
      showSuccessToast('Successfully signed in.')
    } catch (err) {
      getApiErrorHandler()(err as RequestException)
    }
  }

  return (
    <AuthPageLayout>
      <Card>
        <StyledAuthPageCardContent>
          <StyledAuthPageH1>
            Log in to <br /> your account
          </StyledAuthPageH1>
          <form aria-label='sign-in-form' onSubmit={handleSubmit(onSubmit)}>
            <Stack orientation='vertical' space={4}>
              <Stack orientation='vertical' space={3.2}>
                <TextInput
                  ref={register}
                  name='email'
                  label='Email address'
                  type='email'
                  placeholder='Your email address'
                  errorMessage={errors.email && errors?.email.message}
                />
                <PasswordInput
                  ref={register}
                  name='password'
                  label='Password'
                  placeholder='Your password'
                  errorMessage={errors.password && errors?.password.message}
                />
              </Stack>
              <Button
                disabled={formState.isSubmitting}
                type='submit'
                variant='primary'
              >
                {formState.isSubmitting ? (
                  <Loading color={theme.colors.white} size='2rem' />
                ) : (
                  'Log in'
                )}
              </Button>
            </Stack>
          </form>
          <StyledForgotPasswordLink to={routes.forgotPassword}>
            Forgot password?
          </StyledForgotPasswordLink>
        </StyledAuthPageCardContent>
      </Card>

      <StyledAuthPageHelpText>
        <p>
          Problem with logging in? Ask for{' '}
          <a href='mailto:info@faapp.com'>help</a>.
        </p>
      </StyledAuthPageHelpText>
    </AuthPageLayout>
  )
}

export default SignInPage
