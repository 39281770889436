import styled from 'styled-components'
import theme from 'theme'

export interface IStyledDropdownMenuProps {
  isOpen: boolean

  width?: number
}

export const StyledDropdownMenu = styled.ul<IStyledDropdownMenuProps>`
  position: absolute;
  z-index: 1000;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 20rem;
  min-width: 16.8rem;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};

  outline: none;
  list-style: none;
  border: 0.1rem solid ${theme.colors.grey[400]};
  border-radius: 1.2rem;
  background-color: ${theme.colors.white};
  box-shadow: 0 1rem 2rem rgba(156, 158, 172, 0.1);
  transform-origin: top;
  transition: transform ${theme.animations.duration}
      ${theme.animations.timingFunction},
    opacity ${theme.animations.duration} ${theme.animations.timingFunction};
  transform: ${({ isOpen }) =>
    isOpen ? 'scale3d(1, 1, 1)' : 'scale3d(1, 0, 1)'};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
`
